import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import PropertyRegistration from "./pages/PropertyRegistration";
import PropertyDetails from "./pages/PropertyDetails";
import SearchResult from "./pages/SearchResult";
import Company from "./pages/Company";
import Favorites from "./pages/Favorites";
import ParkManagement from "./pages/ParkManagement";
import Error404 from "./pages/Error404";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/:modalidade/destaque-especial/:categoria/:filtros?" component={SearchResult} />
        <Route exact path="/busca/:modalidade/:tipo/:endereco/:finalidade/:filtros?" component={SearchResult} />
        <Route exact path="/detalhes/:modalidade/:codigo/:slug?" component={PropertyDetails} />
        <Route exact path="/cadastre-seu-imovel" component={PropertyRegistration} />
        <Route exact path="/nossa-empresa" component={Company} />
        <Route exact path="/meus-imoveis-favoritos" component={Favorites} />
        <Route exact path="/gestao-de-estacionamentos" component={ParkManagement} />
        <Route exact path="*" component={Error404} />
      </Switch>
    </BrowserRouter>
  );
}