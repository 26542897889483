import React from 'react';
import { Link } from 'react-router-dom'

import './styles.css';

const Button = ({to, label, color, icon, ...rest}) => {
    let button;

    if (to) {
        button = 
        <div className="button-box">
            <Link to={to} {...rest} className={color}> {label} <i className={icon}></i></Link>
        </div>
    } else {
        button =
        <div className="button-box">
            <button {...rest} className={color}> {label} </button>
        </div>
    }

    return (button);
}
export default Button;
