import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { animateScroll as scroll } from 'react-scroll';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import Topo from './Topo';
import Content from './Content';

import Utils from './../../helpers/Utils';
import fields from "./fields";
import Services from "./Services";
import * as constants from './constants';

export default class SearchResult extends Component {

    state= {
        ...fields
    };
    
    constructor(props) {
        super(props);
        this._services = new Services();
    }

    static getDerivedStateFromProps(nextProps, prevState) {      
        let params = (nextProps.match.params.filtros ? nextProps.match.params.filtros : '');
        let params_filtros = {};

        if (params !== '') {
            let filtros = '';
            let padrao = [];
            filtros = params.split('+');

            for (let i in filtros) {                
                padrao = filtros[i].split('-');
                params_filtros[padrao[1]] = padrao[0];                
            }
        }        

        if (
            nextProps.match.params.modalidade === prevState.modalidade ||
            nextProps.match.params.tipo === prevState.tipo ||
            nextProps.match.params.endereco === prevState.endereco ||
            nextProps.match.params.categoria === prevState.destaque) {
            return null;
        }
        
        return {
            modalidade: nextProps.match.params.modalidade,
            tipo: nextProps.match.params.tipo,
            endereco: nextProps.match.params.endereco,
            destaque: nextProps.match.params.categoria,
            finalidade: nextProps.match.params.finalidade,
            filtros: params_filtros          
        };        
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.modalidade !== this.props.match.params.modalidade ||
            prevProps.match.params.tipo !== this.props.match.params.tipo) {
            this.setState({
                ...this.state,
                modalidade: this.props.match.params.modalidade,
                tipo: this.props.match.params.tipo,
                endereco: this.props.match.params.endereco,
                destaque: '',
                offset: 0,
                page: 1                
            }, () => {this.buscar()})
        }               
    }

    componentDidMount () {
        window.scrollTo(0, 0);
        this.setState({loadingContent: true});
        this._services.busca(
            this.state.modalidade,
            this.state.finalidade,
            this.state.tipo,
            this.state.endereco,
            this.state.filtros,
            this.state.page,
            this.state.order,
            this.state.limit,
            this.state.offset,
            this.state.caracSelecionados,
            this.state.caracSelecionadosCondominio,
            this.state.destaque
        )
        .then(response => {
            this.setState({
                imoveis: response.imoveis,
                total: response.total,
                loadingContent: false, 
            });
        })
        .catch(e => console.log(e));
        
        this._services.getCaracteristicasImovel(this.state.modalidade, this.state.tipo)
            .then(options => this.setState({caracteristicas: options}));    
        
        this._services.getCaracteristicasCondominio(this.state.modalidade, this.state.tipo)
            .then(options => this.setState({caracsCondominio: options}));

        this._services.getTiposImoveisOptions(this.state.modalidade)
            .then(tipos => {
                this.setState({tipos: tipos ? tipos : [{'name': 'Selecione','value': 0}]});
            }
        );

        this._services.getbuscaEndereco(this.state.modalidade,'')
            .then(response => this.setState({
                bairros: response.bairros,
                cidades: response.cidades                
            }));
    }   

    buscar = () => {   
        scroll.scrollToTop();
        this.setState({loadingContent: true});
        this._services.busca(
            this.state.modalidade, 
            this.state.tipo, 
            this.state.endereco, 
            this.state.filtros, 
            this.state.page, 
            this.state.order, 
            this.state.limit, 
            this.state.offset, 
            this.state.caracSelecionados, 
            this.state.caracSelecionadosCondominio,
            this.state.destaque
        )
        .then(response => {
            if (this.state.destaque === undefined || this.state.destaque === '') {
                this.props.history.push('/busca/'+this.state.modalidade.toLowerCase()+'/'+Utils.transliterate(this.state.tipo)+'/'+this.state.endereco.toLowerCase()+this.adicionarFiltros());
            } else {
                this.props.history.push(`/aluguel/destaque-especial/${this.state.destaque}${this.adicionarFiltros()}`);
            }
            this.setState({
                imoveis: response.imoveis,
                total: response.total,
                loadingContent: false
            });
        })
        .catch(e => console.log(e));

        this._services.getCaracteristicasImovel(this.state.modalidade, this.state.tipo)
            .then(options => this.setState({caracteristicas: options}));    
        
        this._services.getCaracteristicasCondominio(this.state.modalidade, this.state.tipo)
            .then(options => this.setState({caracsCondominio: options}));  
    };   

    adicionarFiltros() {
        let filtros = this.state.filtros;
        let resultado = "";       
        for (let i in filtros) {
                if (filtros[i] !== '' && 
                filtros[i] !== 0 && 
                filtros[i] !== constants.PRICE_RENT_INITIAL[1] && 
                filtros[i] !== constants.PRICE_SALE_INITIAL[1] &&
                filtros[i] !== constants.AREA_INITIAL[1] 
            ){
                if (i !== 'order'){ resultado += filtros[i] + '-' + i + '+';}
            }
        }
        return '/'+resultado.substr(0,(resultado.length - 1));
    }

    getPrice = (values) => {        
        this.setState({ 
            filtros:{ 
                ...this.state.filtros, 
                valorInicial: values[0],
                valorFinal: values[1]
            }
        }, () => this.buscar());
    };

    getComfortables = (item) => {
        this.setState({ 
            filtros:{ 
                ...this.state.filtros, 
                dormitorios: item.dormitorios,
                wc: item.wc,
                vagas: item.vagas
            }
        }, () => this.buscar());
    }

    getFilters = (price, area, filters) => {
        this.setState({
            ...this.state,
            filtros:{ 
                ...this.state.filtros, 
                valorInicial: price[0],
                valorFinal: price[1],
                areaInicial: area[0],
                areaFinal: area[1],
                dormitorios: filters.filters.dormitorios,
                wc: filters.filters.wc,
                vagas: filters.filters.vagas,
            },
            tipo: filters.tipo,
            endereco: filters.endereco,
            caracSelecionados: filters.caracSelecionados,
            caracSelecionadosCondominio: filters.caracSelecionadosCondominio,
            offset: 0
        }, () => this.buscar());
    }

    handleSelect = (e) => {         
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : '')           
        }, () => {
            this.buscar();
        });
    }; 

    handlePaginacao = (name, value) => {
        this.setState({
            [name]: value
        }, () => {
            this.buscar();
        });
    };

    handlePageChange = (pageNumber) => {    
        this.setState({page: pageNumber});
        this.handlePaginacao('offset', ((this.state.limit * pageNumber) - this.state.limit))
    }

    render() {
        return (
            <React.Fragment>
                <Helmet 
                title={`Imoveis ${this.state.modalidade === 'aluguel' ? 'para alugar': 'à venda'} | 
                    ${process.env.REACT_APP_FULL_NAME}`} 
                />

                <Header />
                <main>
                    <Topo
                        {...this.state}
                        params={this.props.match.params}
                        getPrice={this.getPrice}
                        getComfortables={this.getComfortables}
                        handleCheckedList={this.handleCheckedList}
                        handleCheckedListCondominio={this.handleCheckedListCondominio}
                        getFilters={this.getFilters}
                        handleSelect={this.handleSelect}
                        loadingContent={this.state.loadingContent}
                    />

                    <Content
                        {...this.state}
                        params={this.props.match.params}
                        handlePaginacao={this.handlePaginacao}
                        handlePageChange={this.handlePageChange}
                        loadingContent={this.state.loadingContent}
                    />
                </main>
                <Footer />                
            </React.Fragment>
        );
    }
}