import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Header from './Header';
import Footer from '../../components/Layout/Footer';
import EspecialProperties from './EspecialProperties';
import FeaturedServices from './FeaturedServices';
import PropertyRegistration from './PropertyRegistration';
import Search from './Search';

export default class Home extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title={`${process.env.REACT_APP_FULL_NAME}`} />
                <Header />
                <main>
                    <Search {...this.props}/>
                    <FeaturedServices />
                    <EspecialProperties />
                    <PropertyRegistration />
                </main>
                <Footer />
            </React.Fragment>
        );
    }
} 