import React from 'react';
import Pagination from "react-js-pagination";
import LoadingMask from "react-loadingmask";
import Card from '../../../components/Card';
import TitleLoader from './TitleLoader';
import CardLoader from './CardLoader';
import Utils from './../../../helpers/Utils';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet from 'leaflet';
import 'leaflet.fullscreen/Control.FullScreen';

import './styles.css';

import location from '../../../assets/images/icons/location.svg';

const markerIcon = Leaflet.icon({
    iconUrl: location,
    iconSize:[40, 40],
    iconAnchor:[20, 40],
    popupAnchor: [0, -35]
});

export default function Content(props) {
    let title = '';
    if (props.total > 0) {
        title =
        props.params.tipo ?
            `${props.total} imóve${props.total > 1 ? 'is':'l'} do tipo
             ${props.tipo} ${props.modalidade === 'aluguel' ? 'para alugar':'à venda'} em 
             ${Utils.capitalize(props.params.endereco)}`
        :
            `${props.total} imóve${props.total > 1 ? 'is':'l'} de destaque especial >> ${Utils.capitalize(Utils.retiraHifen(props.params.categoria))}`
    } else {
        title = 'Não foram encontrados imóveis para a busca realizada';
    }

    let paginacao;
        if (props.total > props.limit) {
            paginacao =             
                <div className="content-pagination">
                    <nav>
                    <LoadingMask loading={props.loadingContent} >
                        <Pagination
                            hideDisabled
                            prevPageText='Anterior'
                            nextPageText='Próximo'
                            firstPageText='Início'
                            lastPageText='Fim'
                            activePage={props.page}
                            itemsCountPerPage={props.limit}
                            totalItemsCount={parseInt(props.total)}
                            onChange={props.handlePageChange}
                        />
                    </LoadingMask>
                    </nav>
                </div>
        } else {
            paginacao = ''
        }

    return (
        <div id="search-result">
            <div className="container">
                <div className="head-line">
                {
                    props.loadingContent ?
                    <TitleLoader /> :
                    <h1>{ title }</h1>
                }                 
                </div>
                <div className="search-result-wrapper">
                    <div id="result-card" className="result-card">
                        <div className="content-cards">
                            {props.loadingContent ? 
                                <CardLoader/>:
                                props.imoveis.map((imovel, index) =>                                 
                                <Card 
                                    key={index} 
                                    dados={imovel}
                                    icon='heart'
                                />)
                            }
                        </div>

                        {/* paginacao */}

                    </div>
                
                    <div id="result-map" className="result-map">
                        <Map 
                            center={[-3.7900979, -38.5889878]}
                            zoom={12}
                            style={{ width: '100%', height: '100%' }}
                            fullscreenControl={true}
                        >
                        
                            <TileLayer
                                url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                            />

                            {props.imoveis.map( imovel => (
                                <Marker
                                    key={imovel.id}
                                    position={[imovel.latitude, imovel.longitude]}
                                    icon={markerIcon}
                                    >
                                    <Popup className="popup-map-card-imovel">
                                        <Card dados={imovel} image='/images/demo/img-favorito.jpg' />
                                    </Popup>
                                </Marker>
                            ))}
                        </Map>
                    </div>

                </div>
            </div>
        </div>
    );
}
