import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import api from './../../services/api'


import ContentGallery from './ContentGallery';
import ContentDetails from './ContentDetails';
import SimilarProperty from './SimilarProperty';
import Error404 from './Error404';
import Loader from './Loader';

export default class PropertyDetails extends Component {
    state = {
        imovel: {tituloTextoDestaque:'...', imagens:[]},
        imoveisProximos: [],
        loader: true
    }

    componentDidMount(){
        this.handleLoadImovel();
        window.scrollTo(0, 0);
    }
    
    handleLoadImovel = async(codigo = this.props.match.params.codigo, slug = this.props.match.params.slug) => {
        window.scrollTo(0, 0);
        this.setState({loader: true})
        await api.get(`${this.props.match.params.modalidade}/detalhes`, {
            params: {
                codigo: codigo
            }
        }).then(response => {
            this.setState({
                imovel: response.data.imovel,
                imoveisProximos: response.data.imoveisPrx,
                loader: false
            });
        })

        this.props.history.push(`/detalhes/${this.props.match.params.modalidade}/${codigo}${slug !== undefined ? `/${slug}`: ''}`);
    }

    render(){
        let content = '';
        if (this.state.imovel.id) {
            content =            
                <main>
                    <ContentGallery 
                        modalidade={this.state.imovel.modalidade}
                        codigoLabel={this.state.imovel.codigo_label} 
                        tipo={this.state.imovel.tipo}
                        cidade={this.state.imovel.cidade}
                        imagens={this.state.imovel.imagens}
                    />

                    <ContentDetails imovel={this.state.imovel} />
                    {this.state.imoveisProximos.length > 0 ?
                        <SimilarProperty 
                            tipo={this.state.imovel.tipo}
                            cidade={this.state.imovel.cidade}
                            similares={this.state.imoveisProximos}
                            handleLoadImovel={this.handleLoadImovel} />
                    :''}

                </main>
        } else {
            content = <main><Error404 codigo={this.props.match.params.codigo} /></main>;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`${this.state.imovel.tituloTextoDestaque} | ${process.env.REACT_APP_FULL_NAME}`}</title>
                    <meta name="title" content={this.state.imovel.tituloDetalhes} />
                    <meta name="description" content={this.state.imovel.descricao} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content={this.state.imovel.tituloDetalhes} />
                    <meta property="og:description" content={this.state.imovel.descricao} />
                    <meta property="og:image" content={this.state.imovel.imagens !== undefined && this.state.imovel.imagens.length  > 0 ? this.state.imovel.imagens[0].imagem : ''} />
                    
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location.href} />
                    <meta property="twitter:title" content={this.state.imovel.tituloDetalhes} />
                    <meta property="twitter:description" content={this.state.imovel.descricao} />
                    <meta property="twitter:image" content={this.state.imovel.imagens !== undefined && this.state.imovel.imagens.length > 0 ? this.state.imovel.imagens[0].imagem : ''} />
                </Helmet>

                <Header />
                    {this.state.loader ? <Loader/> : content}
                <Footer />
            </React.Fragment>
        );
    }
}