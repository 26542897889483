import React, { Component } from 'react';
import HeaderLine from '../../../components/HeaderLine';
import CardEspecialProperties from './Cards';
import Button from '../../../components/Button';
import Utils from '../../../helpers/Utils';
import api from './../../../services/api'
import Loader from './Loader';

import imoveisVenda from './../../../assets/images/cards/imoveisParaVenda.jpg'
import './styles.css';

export default class EspecialProperties extends Component {
    state = {
        destaques: []
    }

    componentDidMount(){
        this.handleEspecialProperties();
    }

    handleEspecialProperties = () => {
        api.get('/aluguel/listarDestaquesEspeciais').then(
            response => {
                this.setState({
                    destaques: response.data.resultado
                });
            }
        )
    }

    render() {
        return (
            <section id="especial-properties-section">
                <div className="container">
                    <HeaderLine label="Imóveis especiais para você" />
                    {this.state.destaques.length > 0 ? 
                        <div id="especial-properties-box">                        
                            { this.state.destaques.map((item, index) => {
                                return (
                                    <CardEspecialProperties
                                        key={index}
                                        to={`/aluguel/destaque-especial/${Utils.transliterate(item.highlight)}`}
                                        image={item.image} label={item.label} highlight={item.highlight}
                                    />
                                );
                            })}

                            <CardEspecialProperties 
                                to={`/busca/venda/apartamento/fortaleza/residencial`}
                                image={imoveisVenda} label="Conheça nossos" highlight="destaques para venda"
                            />
                        </div>
                    : <Loader/>}

                    <Button 
                        to='/busca/aluguel/apartamento/fortaleza/residencial'
                        label="Ver todos os imóveis"
                        color="primary"
                        icon="fas fa-chevron-right" 
                    />
                </div>
            </section>
        );
    }
}

