import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Nav from './../../../components/Layout/Nav';

import logomarca from './../../../assets/images/logomarca/logoEstaforHorizontal.jpg';

import './styles.css';
import Topo from '../Topo';

export default class Header extends Component {    
    render(){
        const headerSearch = window.location.pathname.indexOf('/busca/') ? '':'headerSearch';
        return (
            <header id="header" className={headerSearch}>

                <Topo />
                
                <div className="container">
                    <div id="header-content">
                        <Link to='/'>
                            <img src={logomarca} alt={process.env.REACT_APP_NAME} />
                        </Link>
                       
                        <Nav />
                    </div>
                </div>
            </header>
        );
    }
}
