import React from 'react';
import FormRegistration from './FormRegistration';

import './styles.css';

const Topo = () => {
    return (
        <section id="topo-property-registration">
            <div className="container">
                <div className="content">
                    <h1>Anuncie seu imóvel na <strong>Estafor Negócios Imobiliários</strong></h1>
                    <p>
                        Nossa empresa conta com mais de 30 anos de experiência em soluções imobiliárias e trabalha com 
                        os maiores e melhores portais imobiliários do mercado. Veja algumas das vantagens de deixar seu 
                        imóvel conosco:
                    </p>

                    <ul>
                        <li>
                            <i className="fas fa-check-double" />
                            <span>Conectamos seu imóvel a clientes <strong>realmente interessados</strong></span>
                        </li>
                        <li>
                            <i className="fas fa-check-double" />
                            <span>Somos <strong>referência na cidade</strong> em negociação imobiliária</span>
                        </li>
                        <li>
                            <i className="fas fa-check-double" />
                            <span>Seu imóvel negociado por <strong>especialistas em toda região</strong> da cidade</span>
                        </li>
                        <li>
                            <i className="fas fa-check-double" />
                            <span>Fazemos parte da mais seleta rede imobiliária do Ceará, a <strong>AADIC</strong></span>
                        </li>
                    </ul>
                </div>

                <div className="content-form">
                    <FormRegistration />
                </div>
            </div>

        </section>
    );
}

export default Topo;