import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';

import Topo from './Topo';
import AlertBlock from './AlertBlock';
import Benefits from './Benefits';
import Process from './Process';
import { loadReCaptcha } from 'react-recaptcha-google'

export default class PropertyRegistration extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        loadReCaptcha();
    }

    render(){
        return (
            <React.Fragment>
                <Helmet title={`Anuncie seu imóvel | ${process.env.REACT_APP_FULL_NAME}`} />
                <Header />
                <main>
                    <Topo />
                    <AlertBlock />
                    <Benefits />
                    <Process />
                </main>       
                <Footer />                
            </React.Fragment>
        );
    }
}