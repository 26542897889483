import React, { Component } from 'react';
import Menu from '../Menu';
import { Link } from 'react-router-dom';

import Favorites from '../../../helpers/Favorites';

import './styles.css';

export default class Nav extends Component {
    constructor(props){
        super(props);
        this._favorites = new Favorites();
    }

    render() {
        return (
            <nav id='navigation'>
                <Link id="nav-favoritos" to="/meus-imoveis-favoritos">
                    <i className="fas fa-heart"></i> 
                    <span id='totalizadorFavorites' className="fav-count">{this._favorites.getQtdFavorites()}</span>
                    &nbsp;Favoritos
                </Link>

                <Link
                    id="nav-area-cliente" 
                    to={{pathname: process.env.REACT_APP_LOGIN}} 
                    target="_blank" 
                    rel="noopener noreferrer">
                    <i className="fas fa-lock"></i> &nbsp;Login
                </Link>

                <Menu />
            </nav>
        );
    }
}