import React from "react"
import ContentLoader from "react-content-loader"

const Loader = (props) => (
  <ContentLoader
    style={{margin: '0 auto', display: 'block'}}
    speed={2}
    width={600}
    height={600}
    {...props}
  >
    <rect x="7" y="232" rx="5" ry="5" width="188" height="153" /> 
    <rect x="209" y="233" rx="5" ry="5" width="188" height="153" /> 
    <rect x="411" y="233" rx="5" ry="5" width="188" height="153" /> 
    <rect x="411" y="67" rx="5" ry="5" width="188" height="153" /> 
    <rect x="10" y="67" rx="5" ry="5" width="389" height="153" /> 
    <rect x="209" y="396" rx="5" ry="5" width="389" height="153" /> 
    <rect x="9" y="398" rx="5" ry="5" width="188" height="153" />
  </ContentLoader>
)

export default Loader