import React, { Component } from 'react';

import quality from '../../../assets/images/icons/quality.svg';

import './styles.css';

export default class AlertBlock extends Component {
    render() {
        return (
            <section id="alert-block-section">
                <div className="alert-block">
                    <img src={quality} alt='Ética'/>
                    <h3>
                        Somos uma imobiliária relevante na cidade de Fortaleza. Trabalhando sempre com ética e 
                        transparência em todos os nosso processos.
                    </h3>
                </div>           
            </section>
        );
    }
} 