import React, { Component } from 'react';
import CardFeaturedServicesedServices from './CardFeaturedServices';

import barCode from './../../../assets/images/icons/bar-code.svg';
import announcement from './../../../assets/images/icons/announcement.svg';
import parkingCar from './../../../assets/images/icons/parking-car.svg';
import contract from './../../../assets/images/icons/contract.svg';

import './styles.css';

export default class FeaturedServices extends Component {
    render() {
        return (
            <section id="featured-services-section">
                <div className="container">

                    <CardFeaturedServicesedServices 
                        to={process.env.REACT_APP_LOGIN}
                        target="_blank"
                        label="Segunda via de boleto"
                        icon={barCode} 
                    />

                    <CardFeaturedServicesedServices 
                        to={process.env.REACT_APP_LOGIN}
                        target="_blank"
                        label="Extrato de prestação de contas" 
                        icon={contract} 
                    />

                    <CardFeaturedServicesedServices 
                        to="/cadastre-seu-imovel" 
                        label="Anuncie seu imóvel aqui" 
                        icon={announcement} 
                    />                    

                    <CardFeaturedServicesedServices 
                        to="/gestao-de-estacionamentos" 
                        label="Getão de estacionamentos" 
                        icon={parkingCar} 
                    />

                </div>
            </section>
        );
    }
} 