import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import Button from '../../components/Button';

import './styles.css';


export default class Error404 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
 
    render() {
        return (
            <Fragment>
                <Helmet title={`Error 404 | ${process.env.REACT_APP_FULL_NAME}`} />

                <main id="container404">
                    <div className="content">
                        <h2>Error 404</h2>
                        <h1>Página não encontrada</h1>

                        <p>
                            Não encontramos a página que você está procurando. Pode ser que você tenha digitado o endereço 
                            da página incorretamente.
                        </p>

                        <Link to="/" >Clique aqui para voltar a nossa página inicial</Link>

                        <h4>Ou se preferir: </h4>

                        <div className="btn-content">                        
                            <Button
                                to="/busca/aluguel/apartamento/fortaleza"
                                label="Ir para Seção de aluguel"
                                color="primary"
                                icon="fas fa-chevron-right"
                            />

                            <Button
                                to="/busca/venda/apartamento/fortaleza"
                                label="Ir para Seção de venda"
                                color="primary"
                                icon="fas fa-chevron-right"
                            /> 
                        </div>
                    </div>
                </main>
            </Fragment>
        );
    }
}