import React from 'react';

import measure from '../../../../assets/images/icons/measure.svg';
import bed from '../../../../assets/images/icons/bed.svg';
import bathtub from '../../../../assets/images/icons/bathtub.svg';
import garage from '../../../../assets/images/icons/garage.svg';
import code from '../../../../assets/images/icons/qr-code-scan.svg';
import environment from '../../../../assets/images/icons/environment.svg';

import './styles.css';

const EspecialFeatures = ({imovel}) => {

    let caracteristicaPorFinalidade = '';
    if (imovel.finalidade === '1') {
        caracteristicaPorFinalidade =
        <li>
            <img src={bed} alt="Dormitórios"/>
            <span> {imovel.dormitorios_total} dormitorio{imovel.dormitorios_total > 1 ? 's' : ''} </span>
        </li>
    } else {
        caracteristicaPorFinalidade =
        <li>
            <img src={environment} alt="Ambientes"/>
            <span>{imovel.ambientes} ambiente{imovel.ambientes > 1 ? 's' : ''}</span>
        </li>
    }

    return (        
        <div className="block-features">
            <ul>
                <li>
                    <img src={measure} alt="Área"/>
                    <span> { imovel.area_total }m²</span>
                </li>

                {caracteristicaPorFinalidade}

                <li>
                    <img src={bathtub} alt="Banheiros"/>
                    <span> {imovel.wc_total} banheiro{imovel.wc_total > 1 ? 's' : ''}</span>
                </li>
                <li>
                    <img src={garage} alt="Vagas de garagem"/>
                    <span> {imovel.vagas} vaga{imovel.vagas > 1 ? 's' : ''}</span>
                </li>
                <li>
                    <img src={code} alt="Vagas de garagem"/>
                    <span>Cód: {imovel.id}</span>
                </li>
            </ul>
        </div>
        
    );
}

export default EspecialFeatures;