import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../services/api';

import Nav from './Nav';
import Contacts from './Contacts';

import './styles.css';

export default class Footer extends Component {
    state = {
        listImoveis: {residenciais:[], comerciais:[]},
        codigo: ''
    }    
    
    componentDidMount(){
        this.listTiposImoveis();
    }

    listTiposImoveis =  async() => {
        await api.get('/aluguel/tiposImoveisDistintosOptions').then(
            response => {
                this.setState({
                    listImoveis: response.data.options
                });
            }
        )
    }

    now = new Date();
    
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="footer-body">
                        <div className="footer-box">
                            <h2><Link to='/'>Estafor</Link></h2>
                            <ul>                                
                                <li><Link to='/nossa-empresa'>Nossa empresa</Link></li>                              
                                <li>
                                    <Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank' rel="noopener noreferrer"> 
                                        Área cliente
                                    </Link>
                                </li>
                                <li><Link to='/cadastre-seu-imovel'>Anuncie seu imóvel aqui</Link></li>
                                <li><Link to='/busca/venda/apartamento/fortaleza/residencial'>Imóveis à venda</Link></li>
                                <li><Link to='/gestao-de-estacionamentos'>Estacionamentos</Link></li>
                            </ul>
                        </div>

                        <Nav 
                            label="Aluguel Residencial"
                            to={false}
                            items={this.state.listImoveis.residenciais}
                            finalidade="residencial"
                        />                        

                        <Nav 
                            label="Aluguel Comercial"
                            to={false}
                            items={this.state.listImoveis.comerciais}
                            finalidade="comercial"
                        />                         

                        <Contacts />
                    </div>                        
                
                    <div className="footer-bottom">
                        <h2>{process.env.REACT_APP_FULL_NAME}</h2>
                        <p> &copy;{this.now.getFullYear()} - Todos os Direitos Reservados - <Link to='/' >{process.env.REACT_APP_NAME}</Link> - estafor.com.br</p>

                        <div className="redes-social">

                            <Link 
                                to={{pathname: process.env.REACT_APP_WHATSAPP_API}}                                        
                                target="_blank"
                                rel="noopener noreferrer" 
                            >
                                <i className="fa fa-whatsapp"/>
                            </Link>                                        
        
                            <Link
                                to={{pathname: process.env.REACT_APP_INSTAGRAM}}                                        
                                target="_blank"
                                rel="noopener noreferrer" 
                            >
                                <i className="fa fa-instagram"/>
                            </Link>

                        </div>                    
                    </div>                    
                </div>
            </footer>
        );
    }
} 