import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Nav from './../../../components/Layout/Nav';

import logomarca from './../../../assets/images/logomarca/logoEstaforHorizontal.jpg';
import phone from './../../../assets/images/icons/phone.svg';

import './styles.css';

export default class Header extends Component {
    render() {
        return (
            <header id="header-home">
                <h1>{process.env.REACT_APP_NAME}</h1>

                <div className="container">
                    <div id="header-home-content">
                        <Link to='/'>
                            <img src={logomarca} alt={process.env.REACT_APP_NAME} />
                        </Link>

                        <span>
                            <ul>
                                <li>
                                    <img src={phone} alt="contato"/>
                                    <span>Ligue agora <strong>{process.env.REACT_APP_PHONE}</strong>
                                    </span>
                                </li>
                                <li>
                                    <Link 
                                        to={{pathname: process.env.REACT_APP_WHATSAPP_API}}                                        
                                        target="_blank"
                                        rel="noopener noreferrer" >
                                        <i className="fab fa-whatsapp"></i>
                                        <span>Whatsapp <strong>{process.env.REACT_APP_WHATSAPP}</strong>
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to={{pathname: process.env.REACT_APP_INSTAGRAM}}                                        
                                        target="_blank"
                                        rel="noopener noreferrer" >
                                        <i className="fab fa-instagram"></i>
                                        <span>Instagram <strong>@estafornegocios</strong>
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                            
                            <Nav />
                        </span>
                    </div>                    
                </div>
            </header>
        );
    }
} 