const fields = {
    modalidade:'',
    destaque: '',
    tipo: '',
    endereco: '',
    imoveis: [],
    total: '',
    caracteristicas: [],
    caracsCondominio: [],
    tipos:[
        {
            'name': 'Selecione',
            'value': 0
        }
    ],
    bairros: [],
    cidades: [],
    order:'0',
    limit: 12,
    offset: 0,
    page: 1,
    filtros: {
        'valorInicial':'',
        'valorFinal':'',
        'areaInicial':'',
        'areaFinal':'',        
        'ambientes':'',
        'dormitorios':'',
        'wc':'',
        'vagas':'',
    },
    caracSelecionados:[],
    caracSelecionadosCondominio: []
};

export default fields;