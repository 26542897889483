import React from 'react';
import Button from '../../../../components/Button';

import './styles.css';

const Card = ({imovel, handleLoadImovel}) => {
    const dormitorios = imovel.quartos + imovel.suites;
    return (
        <div className="similar-card">
            <div className="card-image">
                {imovel.imagens.map(item => 
                    <img key={item.id} src={item.imagem} alt={item.descricao} onClick={()=>handleLoadImovel(imovel.id, imovel.slug)}/>
                )}
            </div>
            <div className="card-wrapper">
                <h3 className="card-wrapper-type" onClick={()=>handleLoadImovel(imovel.id, imovel.slug)}>
                    { imovel.titulo }
                </h3>
                <span>{imovel.bairro}, {imovel.cidade} - {imovel.estado.toUpperCase()}</span>

                <ul className="features">
                    <li>{ imovel.area_total }m²</li>
                    <li>{dormitorios} dormitorio{dormitorios > 1 ? 's' : ''}</li>
                    <li>{imovel.banheiros} banheiro{imovel.banheiros > 1 ? 's' : ''}</li>
                    <li>{imovel.vagas} vaga{imovel.vagas > 1 ? 's' : ''}</li>
                </ul>

                <div className="card-account">                        
                    R$ { imovel.valor }
                </div>

                <div className="card-buttons">                   
                    <Button
                        onClick={()=>handleLoadImovel(imovel.id, imovel.slug)}
                        label="Mais detalhes" 
                        color="primary"
                    />
                </div>
            </div>
        </div>
    );
}

export default Card;