import React, { Fragment, useState, useEffect } from 'react';
import Select2 from "react-select2-wrapper";
import { Input as Select } from 'reactstrap';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Item from '../Comfortable/Item';
import Button from './../../../../../components/Button';
import Utils from './../../../../../helpers/Utils';
import * as constants from './../../../constants';

import './styles.css';

export default function Drawer(props) {
    const initialPrice = props.modalidade === 'aluguel' ? constants.PRICE_RENT_INITIAL : constants.PRICE_SALE_INITIAL;   
    const initialArea = constants.AREA_INITIAL;

    const [price, setPrice] = useState(initialPrice);
    const [stepPrice, setStepPrice] = useState(constants.STEP_RENT);

    const [area, setArea] = useState(initialArea);

    const [modalidade] = useState(props.modalidade);
    
    const [state, setState] = useState({
        filters: {
            dormitorios: props.filtros.dormitorios > 0 ? Number(props.filtros.dormitorios): 0,
            wc: props.filtros.wc > 0 ? Number(props.filtros.wc) : 0,
            vagas: props.filtros.vagas > 0 ? Number(props.filtros.vagas) : 0,
        },
        tipo: props.tipo,
        endereco: props.endereco,
        caracSelecionados: [],
        caracSelecionadosCondominio: []
    });

    const listBairros = props.listBairros.map((bairro) => (
            { text: Utils.ucWords(bairro.text), id: Utils.transliterate(bairro.id) }
    ));

    const listCidades = props.listCidades.map((cidade) => (
            { text: Utils.ucWords(cidade.text), id: Utils.transliterate(cidade.id) }
    ));

    const closeDrawer = () => {
        document.querySelector('#drawer-overlay').classList.add('no-overlay'); 
        document.querySelector('#drawer').classList.add('no-drawer');     
    }

    const handleSelect = (e) => {         
        setState({
            ...state,
            [e.target.name]: (e.target.value !== null ? e.target.value : '')            
        });
    };

    const handleChangeArea = (event, values) => {
        setArea(values);
    };

    const handleChangePrice = (event, values) => {
        setPrice(values);
    };

    const increment = (item) => {
        if (state.filters[item] < 5 ) {
            setState({ ...state, filters: {
                ...state.filters,
                [item]: state.filters[item] + 1
            }});
        }
    }

    const decrement = (item) => {
        if (state.filters[item] > 0 ) {
            setState({ ...state, filters: {
                ...state.filters,
                [item]: state.filters[item] - 1
            }});
        }
    }    

    const handleCheckedList = (e) => {
        let list = state.caracSelecionados;
        (
            e.target.checked ? 
            list.push({name: e.target.name , value: e.target.value }) : 
            list.splice(list.findIndex(index => index.value === e.target.value), 1)
        );

        setState({...state, caracSelecionados: list});        
    }

    const handleCheckedListCondominio = (e) => {
        let list = state.caracSelecionadosCondominio;        
        (
            e.target.checked ? 
            list.push({name: e.target.name , value: e.target.value }) : 
            list.splice(list.findIndex(index => index.value === e.target.value), 1)
        )
        setState({...state, caracSelecionadosCondominio: list, offset: 0});
    }    

    const handleClick = (price, area, state) => {
        props.getFilters(price, area, state);
        closeDrawer()
    }

    const clear = () => {
        setPrice(initialPrice);
        setArea(initialArea);
        setState({
            filters: {
                dormitorios: 0,
                wc: 0,
                vagas: 0,
            },
            tipo: 'Apartamento',
            endereco: 'fortaleza',
            caracSelecionados: props.caracSelecionados,
            caracSelecionadosCondominio: props.caracSelecionadosCondominio
        });        
    }

    useEffect(() => {        
        const valorInicial = (isNaN(props.preco[0]) ? initialPrice[0] : props.preco[0]);
        const valorFinal = (isNaN(props.preco[1]) ? initialPrice[1] : props.preco[1] );
        const initialPriceValues = [valorInicial, valorFinal];

        const step = modalidade === 'aluguel' ? constants.STEP_RENT: constants.STEP_SALE;
        setPrice(initialPriceValues);
        setStepPrice(step);        

        const areaInicial = (isNaN(props.area[0]) ? initialArea[0] : props.area[0]);
        const areaFinal = (isNaN(props.area[1]) ? initialArea[1] : props.area[1] );

        const areaInitial =[areaInicial, areaFinal];
        setArea(areaInitial);

        setState({
            filters: {
                dormitorios: props.filtros.dormitorios > 0 ? Number(props.filtros.dormitorios): 0,
                wc: props.filtros.wc > 0 ? Number(props.filtros.wc) : 0,
                vagas: props.filtros.vagas > 0 ? Number(props.filtros.vagas) : 0,
            },
            tipo: props.tipo,
            endereco: props.endereco,
            caracSelecionados: props.caracSelecionados,
            caracSelecionadosCondominio: props.caracSelecionadosCondominio
        })
    },[props]);
    
    return (
        <Fragment>
            <div id="drawer-overlay" className="overlay no-overlay" onClick={closeDrawer}></div>
            <div id="drawer" className="drawer no-drawer">
                <form>
                    <div className="drawer-header">
                        <i className="far fa-times-circle" onClick={closeDrawer}></i>
                        <h2>Mais filtros</h2>
                    </div>

                    <div className="box-drawer box-drawer-endereco">
                        <div className="box-drawer-location">
                            <span>Localidade</span>
                            <Select2
                                name="endereco"
                                className="form-control"
                                data={[
                                    { text: 'Cidades',
                                        children: listCidades,
                                    },
                                    { text: 'Bairros',
                                        children: listBairros,
                                    }
                                ]}
                                options={{
                                    placeholder: 'Selecione um bairro ou cidade',
                                }}
                                value={state.endereco}
                                required={true}
                                onSelect={(e) => handleSelect(e)}
                            />
                        </div>
                    </div>
                    <div className="box-drawer">
                        <div className="box-drawer-select">
                            <span>Tipo</span>
                            <Select                      
                                type="select"
                                name="tipo"                           
                                value={state.tipo}
                                onChange={(e) => handleSelect(e)}
                                required={true}
                            >                                
                                {props.listTipos.map((tipo, index) => (
                                    <option key={index} value={tipo.value}>{tipo.name}</option>
                                ))}                                
                            </Select>                        
                        </div>
                    </div>
                    <div className="box-drawer box-drawer-price">
                        <div className="box-drawer-slider">
                            <span>Preço</span>
                            <span>
                                R$ {Utils.floatToBRFormat(price[0]).replace(',00','')} -
                                R$ {Utils.floatToBRFormat(price[1]).replace(',00','')}
                            </span>
                        </div>
                        <Slider
                            value={price}
                            onChange={handleChangePrice}
                            max={initialPrice[1]}
                            step={stepPrice}
                        />
                    </div>                    
                    <div className="box-drawer">
                        <div className="box-drawer-slider">
                            <span>Área</span>
                            <span>
                                {Utils.floatToBRFormat(area[0]).replace(',00','')}m² -
                                {Utils.floatToBRFormat(area[1]).replace(',00','')}m²
                            </span>
                        </div>
                        <Slider
                            value={area}
                            onChange={handleChangeArea}
                            max={initialArea[1]}
                            step={constants.STEP_AREA}
                        />
                    </div>
                    <div className="box-drawer-comfortable">
                        <Item label="Dormitorios" counter={state.filters.dormitorios} increment={increment} decrement={decrement} />
                        <Item label="Banheiros" counter={state.filters.wc} increment={increment} decrement={decrement} />
                        <Item label="Vagas" counter={state.filters.vagas} increment={increment} decrement={decrement} /> 
                    </div>

                    {props.caracteristicas !== null && props.caracteristicas.length > 0 ?
                    <div className="box-drawer-list">                    
                        <h2>Imóveis</h2>
                        <div className="box-drawer-checked">
                            {props.caracteristicas.map((caracteristica, index) => (                          
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox 
                                            name={caracteristica.name}
                                            value={caracteristica.value ?? ''}                                       
                                            onChange={(e) => handleCheckedList(e)}                                           
                                            color="primary"
                                        />
                                    }
                                    label={caracteristica.name}
                                />                       
                            ))}
                        </div>
                    </div>
                    : ''}

                    {props.caracsCondominio !== null && props.caracsCondominio.length > 0 ? 
                    <div className="box-drawer-list">                    
                        <h2>Edifício</h2>
                        <div className="box-drawer-checked">                            
                            {props.caracsCondominio.map((caracsCondominio, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox 
                                            name={caracsCondominio.name}
                                            value={caracsCondominio.value ?? ''}
                                            onChange={(e) => handleCheckedListCondominio(e)}
                                            color="primary"
                                        />
                                    }
                                    label={caracsCondominio.name}
                                />                       
                            ))}
                        </div>
                    </div>
                    : ''}

                    <div className="box-drawer-buttons">
                        <Button type='button' label="Limpar" color="secondary" onClick={clear} />
                        <Button
                            type='button'
                            label="Aplicar"
                            color="primary"
                            onClick={()=>handleClick(price, area, state)} />
                    </div>
                </form>
            </div>
        </Fragment>
    );
}
