import React, { useEffect, useState } from 'react';
import Slider from '@material-ui/core/Slider';
import Button from './../../../../../components/Button';

import Utils from './../../../../../helpers/Utils';
import * as constants from "../../../constants";

import './styles.css';

export default function Price (props) {
    const initial = props.modalidade === 'aluguel' ? constants.PRICE_RENT_INITIAL : constants.PRICE_SALE_INITIAL;

    const [value, setValue] = useState(initial);
    const [step, setStep] = useState(constants.STEP_RENT);
    const [modalidade] = useState(props.modalidade);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const clear = () => {
        setValue(initial);
    };

    const handleClick = (value) => {
        props.getPrice(value)
        props.handleClose();
    }

    useEffect(() => {     
        const valorInicial = (isNaN(props.preco[0]) ? initial[0] : props.preco[0]);
        const valorFinal = (isNaN(props.preco[1]) ? initial[1] : props.preco[1] );
        const initialPriceValues = [valorInicial, valorFinal];

        setValue(initialPriceValues);

       const step = modalidade === 'aluguel' ? constants.STEP_RENT: constants.STEP_SALE;
       setStep(step);
    },[props.preco]);
    
    return (
        <div id="preco-box">
            <div className="header-preco-box">
                <i className="far fa-times-circle" onClick={props.handleClose} />
                <span>{Utils.capitalize(modalidade)}</span>                
            </div>
            
            <span className="faixa-preco">
                R$ {Utils.floatToBRFormat(value[0]).replace(',00','')} -
                R$ {Utils.floatToBRFormat(value[1]).replace(',00','')}
            </span>

            <Slider
                value={value}
                name='price'
                onChange={handleChange}
                max={initial[1]}
                step={step}
            />

            <div className="preco-buttons">
                <Button label="Limpar" color="secondary" onClick={clear} />
                <Button label="Aplicar" color="primary" onClick={()=>handleClick(value)} />
            </div>
        </div>
    );
}