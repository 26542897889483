import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Favorites from '../../../../helpers/Favorites';
import Utils from '../../../../helpers/Utils';
import Contato from './Contato';

import './styles.css';

export default class Agendamento extends Component {
    constructor(props){
        super(props);
        this._favorites = new Favorites();

        this.state = {
            modal: false
        }
    } 

    toggle = () => {
        this.setState({modal: !this.state.modal})        
    }

    render() {
        return (
            <div className="block-visit">
                <i 
                    id={`icon_${this.props.imovel.id}`}
                    onClick={() => this._favorites.update(this.props.imovel)}
                    className={this._favorites.getIcons(this.props.imovel.id, 'heart')}
                />

                <h2>{Utils.capitalize(this.props.imovel.modalidade)} <span>R$ {this.props.imovel.valor}</span></h2>

                <button type='button' onClick={()=>this.toggle()}>
                    <i className="fa fa-envelope-o" /> Agendamento via e-mail
                </button>

                <Contato 
                    isOpen={this.state.modal}
                    toggle={()=>this.toggle()}
                    modalidade={this.props.imovel.modalidade}
                    codigo={this.props.imovel.id}
                    titulo={this.props.imovel.tituloDetalhes}
                    bairro={this.props.imovel.bairro}
                    cidade={this.props.imovel.cidade}
                    estado={this.props.imovel.estado}
                />
              
                <Link
                    to={{pathname: process.env.REACT_APP_WHATSAPP_API}}                                        
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp">
                    <i className="fa fa-whatsapp" /> Agendamento via Whatsapp
                </Link>
                <ul>
                    <li>
                        <span>Condomínio</span>
                        <span>{
                            this.props.imovel.valor_condominio !== '0,00' && this.props.imovel.valor_condominio !== 0 ? 
                            `R$ ${this.props.imovel.valor_condominio}/mês` : 'Não informado'}
                        </span>
                    </li>
                    <li>
                        <span>IPTU</span>
                        <span>{
                            this.props.imovel.valor_iptu !== '0,00' && this.props.imovel.valor_iptu !== 0 ? 
                            `R$ ${this.props.imovel.valor_iptu}` : 'Não informado'}
                        </span>
                    </li>
                </ul>

                <div className="block-alert">
                    <i className="fas fa-exclamation-circle" />
                    <p>
                        Recomendamos que entre em contato com a nossa central de atendimento, pois os valores do condomínio e IPTU podem sofrer alterações. 
                    </p>
                </div>
            </div>        
        );
    }
}