import React from 'react';
import { Link } from "react-router-dom";

import './styles.css';

export default function Error404({codigo}) {
    return (
        <div className="container">
            <div id="card-error404">
                <h1>
                    <i className="fa fa-unlink"></i>
                    Oops! Imóvel não encontrado.
                </h1>
                <h2>
                    Não encontramos o imóvel de código <strong>{codigo.toUpperCase()}</strong> que você está procurando.&nbsp;
                    Pode ser que você tenha digitado o código do imóvel incorretamente.
                </h2>
                <Link to={'/'}>                        
                    Clique aqui para volta e realizar nova busca &nbsp;
                    <i className="fa fa-rotate-left"></i>
                </Link>
            </div>
        </div>
    );    
}