import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FieldBox from './components/FieldBox';

import './styles.css';

export default class Search extends Component {
    render() {
        return (
            <section id="search-section">
                <span id="creditos-imagem">
                    <Link to={{pathname:process.env.REACT_APP_CREDITOS_FOTOS}} target="-blank" rel="noopener noreferrer">                            
                        <i className="fab fa-instagram" /> &nbsp;&nbsp;@camaraportoarquitetura
                    </Link>
                </span>

                <div className="container">
                   <FieldBox {...this.props} />
                </div>
            </section>
        );
    }
} 