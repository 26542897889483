import React, { Component } from 'react';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import { Link } from 'react-scroll';
import HeaderLine from '../../../components/HeaderLine';

import numberOne from '../../../assets/images/icons/number-one.svg';
import numberTwo from '../../../assets/images/icons/number-two.svg';
import numberThree from '../../../assets/images/icons/number-three.svg';

import './styles.css';

export default class Process extends Component {
    render() {
        return (
            <section id="process-property-registration">
                <div className="container">
                    <HeaderLine label="Veja como funciona:" />

                    <div className="process-content">
                        <article>
                            <img src={numberOne} alt="Cadastro"/>
                            <span>
                                <h2>Cadastro</h2>
                                <p>Preencha o formulário do início desta página com as informaçoes do seu imóvel.</p>
                            </span>
                        </article>

                        <article>
                            <img src={numberTwo} alt="Captação"/>
                            <span>
                                <h2>Captação</h2>
                                <p>Seu contato será direcionado para um de nossos colaboradores para iniciar seu atendimento</p>
                            </span>
                        </article>

                        <article>
                            <img src={numberThree} alt="Divulgação"/>
                            <span>
                                <h2>Divulgação</h2>
                                <p>Será agendada uma visita para que sejam feitas a avaliação e as fotos do imóvel</p>
                            </span>
                        </article>
                    </div>

                    <footer>
                        <p>
                            Clique no botão abaixo para agendar uma visita e seguir com os 
                            demais detalhes da divulgação.
                        </p>
                        <Link 
                            to="topo-property-registration" 
                            smooth={true} 
                            duration={800}
                            className="primary"                            
                        >
                            Anunciar meu imóvel <span>na Estafor</span>&nbsp;
                            <VerticalAlignTopIcon/>
                        </Link>
                    </footer>                    
                </div>          
            </section>
        );
    }
} 