import React from "react"
import ContentLoader from "react-content-loader"

const CardLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1240}
    height={800}
    {...props}
  >
    <rect x="15" y="0" rx="10" ry="10" width="380" height="250" /> 
    <rect x="22" y="271" rx="0" ry="0" width="344" height="20" /> 
    <rect x="22" y="302" rx="0" ry="0" width="106" height="20" /> 
    <rect x="25" y="335" rx="0" ry="0" width="165" height="10" />

    <rect x="430" y="0" rx="10" ry="10" width="380" height="250" />
    <rect x="437" y="271" rx="0" ry="0" width="344" height="20" /> 
    <rect x="437" y="302" rx="0" ry="0" width="106" height="20" /> 
    <rect x="440" y="335" rx="0" ry="0" width="165" height="10" /> 

    <rect x="845" y="0" rx="10" ry="10" width="380" height="250" />
    <rect x="852" y="271" rx="0" ry="0" width="344" height="20" /> 
    <rect x="852" y="302" rx="0" ry="0" width="106" height="20" /> 
    <rect x="855" y="335" rx="0" ry="0" width="165" height="10" />

    <rect x="15" y="420" rx="10" ry="10" width="380" height="250" />
    <rect x="22" y="691" rx="0" ry="0" width="344" height="20" />
    <rect x="22" y="722" rx="0" ry="0" width="106" height="20" /> 
    <rect x="25" y="755" rx="0" ry="0" width="165" height="10" />

    <rect x="430" y="420" rx="10" ry="10" width="380" height="250" />
    <rect x="437" y="691" rx="0" ry="0" width="344" height="20" />
    <rect x="437" y="722" rx="0" ry="0" width="106" height="20" /> 
    <rect x="440" y="755" rx="0" ry="0" width="165" height="10" />

    <rect x="845" y="420" rx="10" ry="10" width="380" height="250" />
    <rect x="852" y="691" rx="0" ry="0" width="344" height="20" />
    <rect x="852" y="722" rx="0" ry="0" width="106" height="20" /> 
    <rect x="855" y="755" rx="0" ry="0" width="165" height="10" />
       


  </ContentLoader>
)

export default CardLoader