export default class Utils {
  static transliterate(text){
    text = text.replace(new RegExp('[âãàáä]','gi'), 'a');
    text = text.replace(new RegExp('[ÊÈÉË]','gi'), 'E');
    text = text.replace(new RegExp('[êèéë]','gi'), 'e');
    text = text.replace(new RegExp('[ÎÍÌÏ]','gi'), 'I');
    text = text.replace(new RegExp('[îíìï]','gi'), 'i');
    text = text.replace(new RegExp('[ÔÕÒÓÖ]','gi'), 'O');
    text = text.replace(new RegExp('[ôõòóö]','gi'), 'o');
    text = text.replace(new RegExp('[ÜÚÙÛ]','gi'), 'U');
    text = text.replace(new RegExp('[ûúùü]','gi'), 'u');
    text = text.replace(new RegExp('[Ç]','gi'), 'C');
    text = text.replace(new RegExp('[ç]','gi'), 'c');
    text = text.replace(new RegExp('[.() ]','gi'), '-');
    return text.toLowerCase();
  }

  static retiraHifen(palavra){        
    palavra = palavra.replace(new RegExp('[-]','gi'), ' ');
    return palavra.toLowerCase();
  }

  static capitalize(palavra){      
      let stringCapitalize  =  palavra.toLowerCase();
      return stringCapitalize.substring(0,1).toUpperCase().concat(stringCapitalize.substring(1)); 
  }

  static ucWords(text) {
    let loweredText = text.toLowerCase();
    let words = loweredText.split(" ");
    
    for (let a = 0; a < words.length; a++) {
        let w = words[a];    
        let firstLetter = w[0];             
        w = firstLetter.toUpperCase() + w.slice(1);    
        words[a] = w;
    }
    
    return words.join(" ");
  }

  static floatToBRFormat = (number) => {
      let tmp = number.toFixed(2) + '';
      tmp = tmp.replace('.', '');
      tmp = tmp.replace(/([0-9]{2})$/g, ",$1");

      if (tmp.length > 6) {
        tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
      }
  
      if (tmp.length > 10) {
        tmp = tmp.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2})$/g,'.$1.$2,$3');
      }      
  
      return tmp;
  };

  static fadeOut(id, time) {
    let target = document.getElementById(id);
    let ini = 100;
    let fin = 0
    let alpha = ini;
    let inc;
    
    if (fin >= ini) { 
        inc = 2; 
    } else {
        inc = -2;
    }

    let timer = (time * 1000) / 50;

    let i = setInterval(
      function() {
          if ((inc > 0 && alpha >= fin) || (inc < 0 && alpha <= fin)) {
              clearInterval(i);
          }
          target.style.filter = "alpha(opacity="+ alpha +")";
          target.style.opacity = alpha/100;
          alpha += inc;
      }, timer
    );
  }
}