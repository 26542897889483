import React, { useState, useEffect } from 'react';
import Button from './../../../../../components/Button';

import Item from './Item';

import './styles.css';

export default function Comfortables (props) {
    const [state, setState] = useState({
        filters: {
            dormitorios: props.filtros.dormitorios > 0 ? Number(props.filtros.dormitorios): 0,
            wc: props.filtros.wc > 0 ? Number(props.filtros.wc) : 0,
            vagas: props.filtros.vagas > 0 ? Number(props.filtros.vagas) : 0
        }
    });

    const increment = (item) => {
        if (state.filters[item] < 5 ) {
            setState({ filters: {
                ...state.filters,
                [item]: state.filters[item] + 1
            }});
        }
    }

    const decrement = (item) => {
        if (state.filters[item] > 0 ) {
            setState({ filters: {
                ...state.filters,
                [item]: state.filters[item] - 1
            }});
        }
    }

    const handleClick = (values) => {
        props.getComfortables(values)
        props.handleClose();
    }
    const clear = () => {
        setState({filters:{dormitorios: 0, wc:0, vagas:0}});
    };

    useEffect(() => {
        setState({
            filters: {
                dormitorios: props.filtros.dormitorios > 0 ? Number(props.filtros.dormitorios): 0,
                wc: props.filtros.wc > 0 ? Number(props.filtros.wc) : 0,
                vagas: props.filtros.vagas > 0 ? Number(props.filtros.vagas) : 0
            }
        })
    },[props.filtros]);

    return (
        <div id="comfortable-box">
            <i className="far fa-times-circle" onClick={props.handleClose}></i>

            <Item label="Dormitórios" counter={state.filters.dormitorios} increment={increment} decrement={decrement} />
            <Item label="Banheiros" counter={state.filters.wc} increment={increment} decrement={decrement} />
            <Item label="Vagas" counter={state.filters.vagas} increment={increment} decrement={decrement} />            
            
            <div className="comfortable-buttons">
                <Button label="Limpar" color="secondary" onClick={clear} />
                <Button label="Aplicar" color="primary" onClick={()=>handleClick(state.filters)} />
            </div>

        </div>
    );
}