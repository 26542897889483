import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import Utils from './../../../../helpers/Utils'

const Breadcrumbs = ({params}) => {    
    return (
        <Breadcrumb>

            <BreadcrumbItem>
                <Link to='/'>Estafor</Link>
            </BreadcrumbItem>

            {params.tipo ? 
                <BreadcrumbItem>
                    <Link to={`/busca/${params.modalidade}/${params.tipo}/${params.endereco}`}>
                        {`Imóveis para ${params.modalidade}`}
                    </Link>
                </BreadcrumbItem>
            : ''}

            <BreadcrumbItem active>
                {params.tipo ?? `Destaque especial >> ${Utils.capitalize(Utils.retiraHifen(params.categoria))}`}
            </BreadcrumbItem>

        </Breadcrumb>
    );
}
export default Breadcrumbs;