import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={380}
    height={250}
    viewBox="0 0 380 250"
    {...props}
  >
    <rect x="148" y="118" rx="3" ry="3" width="18" height="18" /> 
    <rect x="178" y="118" rx="3" ry="3" width="18" height="18" /> 
    <rect x="208" y="118" rx="3" ry="3" width="18" height="18" />
  </ContentLoader>
)

export default MyLoader