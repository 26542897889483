import React from 'react';
import Button from '../../components/Button';

import card from '../../assets/images/icons/card-favorites.svg';

import './styles.css';

const NoFavorites = () => {
    return(
        <div className="block-favorites">
            <h2>Oops! Você ainda não favoritou imóveis</h2>
            <p>Veja abaixo como adicionar um imóvel aos favoritos</p>

            <div className="block-wrapper">
                <img src={card} alt="Card"/>               

                <div className="no-favorites-content">
                    <h2>
                        Na página de busca por imóveis 
                        Clique no <span>Coração</span> para favoritar seus imóveis
                    </h2>

                    <h3>Ou se preferir:</h3>

                    <Button
                        to="/busca/aluguel/apartamento/fortaleza"
                        label="Ir para Seção de aluguel"
                        color="primary"
                        icon="fas fa-chevron-right"
                    />

                    <Button
                        to="/busca/venda/apartamento/fortaleza"
                        label="Ir para Seção de venda"
                        color="primary"
                        icon="fas fa-chevron-right"
                    />                
                </div>

            </div>            
        </div>
    );
}

export default NoFavorites;