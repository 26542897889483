import React, { Component } from 'react';
import HeaderLine from '../../../components/HeaderLine';
import Card from '../../../components/CardInformation';

import recompensa from '../../../assets/images/icons/recompensa.svg';
import trophy from '../../../assets/images/icons/trophy.svg';
import security from '../../../assets/images/icons/security.svg';

import './styles.css';

export default class Benefits extends Component {
    render() {
        return (
            <section id="benefits-property-registration">
                <div className="container">
                    <HeaderLine label="Por que escolher a Estafor?" />

                    <div className="benefits-box">
                        <Card 
                            icon={recompensa} 
                            title="Solidez" 
                            text="Desde 1989, a empresa administra locações residenciais e comerciais em Fortaleza e 
                            região, muito se orgulhando da relação de confiança e amizade construída com seus clientes e parceiros." 
                        />

                        <Card 
                            icon={trophy} 
                            title="Atendimento" 
                            text="Nossa equipe está pronta para oferecer um atendimento personalizado de qualidade, 
                            seja comprando, vendendo ou alugando seu imóvel." 
                        />

                        <Card 
                            icon={security} 
                            title="Segurança" 
                            text="Visitas acompanhadas, contratos juridicamente perfeitos e acompanhamento técnico e 
                            jurídico são apenas algumas das vantagens de ser nosso cliente." 
                        />
                    </div>
                </div>
            </section>
        );
    }
} 